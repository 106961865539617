import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { MicroFrontends, updateMicroFrontends } from 'src/models/microFrontends';
import { fetcher } from 'src/utils/fetcher';

const WANT_WORK_URL = '/shards/wantwork';

const TrlKeys = {
    wantWorkHere: 'employerView.actions.wantWorkHere.buttonModal',
};

declare global {
    interface FetcherGetApi {
        [WANT_WORK_URL]: {
            queryParams: void;
            response: {
                microFrontends: MicroFrontends;
            };
        };
    }
}

const WantWorkButton: TranslatedComponent<{ stretched?: boolean }> = ({ trls, stretched }) => {
    const dispatch = useDispatch();
    const handleButtonClick = useCallback(async () => {
        const { microFrontends } = await fetcher.get(WANT_WORK_URL);
        if (microFrontends) {
            dispatch(updateMicroFrontends(microFrontends));
        }
    }, [dispatch]);

    return (
        <Button size="small" style="neutral" mode="secondary" onClick={handleButtonClick} stretched={stretched}>
            {trls[TrlKeys.wantWorkHere]}
        </Button>
    );
};

export default translation(WantWorkButton);
