import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import employerUploadLogoFormSubmit, {
    ActionType,
    LoadType,
} from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/employer_upload_logo_form_submit';

import defaultError from 'src/components/Notifications/DefaultError';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { setLogoStatus, Status } from 'src/models/employer/employerLogo';
import { fetcher } from 'src/utils/fetcher';

import uploadLogoCheckStatus from 'src/components/Employer/Sidebar/uploadLogoCheckStatus';

const UPLOAD_LOGO_URL = '/employer/edit/logo';

declare global {
    interface FetcherPostApi {
        [UPLOAD_LOGO_URL]: {
            queryParams: void;
            body: {
                file: File;
            };
            response: void;
        };
    }
}
export interface AnalyticsParams {
    actionType: ActionType;
    employerId: number;
    employerPageConstructorIsActive?: boolean | undefined | null;
    loadType: LoadType;
    filename: string;
    fileSizeKb: number;
    fileHeight: number;
    fileWidth: number;
    fileType: string;
    errors?: string | undefined | null;
}

interface UploadLogoParams {
    file: File;
    analyticsParams: AnalyticsParams;
}

export default (
        { file, analyticsParams }: UploadLogoParams,
        addNotification: AddNotification,
        handleExitModal: () => void
    ): ThunkAction<Promise<unknown>, DefaultRootState, unknown, AnyAction> =>
    (dispatch) => {
        dispatch(setLogoStatus(Status.Fetching));
        return fetcher.postFormData(UPLOAD_LOGO_URL, { file }).then(
            () => {
                employerUploadLogoFormSubmit(analyticsParams);
                dispatch(uploadLogoCheckStatus(addNotification, handleExitModal)).catch(console.error);
            },
            () => {
                dispatch(setLogoStatus(Status.Fail));
                addNotification(defaultError);
            }
        );
    };
